*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav{
  width: 100%;
  height: 80px;
  background-color: rgba(23, 6, 8, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  position: fixed;
  z-index: 100;
}
nav ul{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
nav ul li{
  font-family: "Manrope", sans-serif;
  color: white;

  font-weight: 700;
  font-size: 16px;
  list-style: none;

}
.banner{
  background-image: url(./Assets/image\ 41.svg);
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}  

.banner h1{
  font-family: "Space Grotesk", sans-serif;
  font-size: 96px;
  font-weight: 500;
  color: white;
  text-align: center;

}
.banner p{
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: white;
  text-align: center;

}
.playercount{
  Width:
209px;
Height:
26px;
background: linear-gradient(
  to right, 
  rgba(74, 9, 103, 1), 
  rgba(147, 18, 205, 0.2)
);
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
border-radius: 20px;
}
.onn{
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: rgba(253, 165, 15, 1);
}
.playercount p{
  font-family: "Manrope", sans-serif;
  font-size: 13px;
  font-weight:700;
  color: white;
}
.banner button{
  height: 53px;
  width: 222px;
  background-color: white;
  border-radius: 100px;
  color: rgba(0, 0, 0, 1);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight:700;
}
.bestone{
  background-color: black;
  padding-top: 120px;
  padding-bottom: 120px;
}
.bestone h2{
  color: rgba(255, 255, 255, 1);
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}
.bestone h2 span{
  color: rgba(253, 165, 15, 1);
}

.rummycard{
  Width:
300px;
Height:
381px;
background-color: rgba(26, 26, 26, 1);
position: relative;
z-index: 0;
}
.rummycard-text{
  color: white;
  padding: 20px;
}
.point{
  height: 11px;
  width: 11px;
  background-color: rgba(253, 165, 15, 1);
  border-radius: 50%;
}
.dp-text{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.rummycard-text h3{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.rummycard-text p{
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.rummycards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.icon{
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 10;
}
.playrummy{
  background-image: url(./Assets/playrummy.svg);
  padding-top: 250px;
  padding-bottom: 200px;
  padding-left: 300px;
  padding-right: 300px;
  background-size: cover;
  text-align: center;
}
.playrummy h3{
  color: rgba(255, 255, 255, 1);
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}
.playrummy h3 span{
  color: rgba(253, 165, 15, 1);
}
.playrummy p{
  color: rgba(255, 255, 255, 1);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.playrummy button{
  width: 172px;
  height: 53px;
  border-radius: 100px;
  border: 4px solid rgba(98, 0, 122, 0.5);
  background-color: rgba(98, 0, 122, 1);
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: white;
}
.tab-divs{
  background-color: black;
  padding-top: 100px;
  padding-bottom: 100px;
}
.tabs{
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
gap: 50px;
}
.tab{
  height: 60px;
 
  border-radius: 31px;
  background-color: rgba(26, 26, 26, 1);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  gap: 15px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;

}
.tab-point{
  height: 40px;
  width: 40px;
  background-color: rgba(98, 0, 122, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
.tab:hover{
  background-color: rgba(98, 0, 122, 1);
}
.tab:hover .tab-point{
  background-color: rgba(26, 26, 26, 1);

}
.allgames{
  background-image: url(./Assets/Group\ 39936.png);
  padding-top: 50px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}
.gameuppers{
  display: flex;
  flex-direction: row;
}
.gameuppers-left{
  width: 40%;

  color: white;
  padding: 50px;
}
.gameuppers-right{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

}
.gameuppers-left h1{
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
}
.gameuppers-left h1 span{
  color: rgba(253, 165, 15, 1);
}
.gameuppers-left p{

font-family: "Manrope", sans-serif;
font-size: 16px;
font-weight: 500;
}

.gameuppers-card{
  Width:
352px;
Height:
325px;
background-color: rgba(26, 26, 26, 1);

border-radius: 16px;
display: flex;
flex-direction: column;

align-items: center;
text-align: center;
padding: 20px;
}
.gamename{
  Width:
98px;
Height:
98px;
border-radius: 50%;
border: 1px solid white;
background-color: rgba(101, 74, 46, 1);
position: absolute;
margin-top: -80px;
display: flex;
justify-content: center;
align-items: center;

}
.gameuppers-card h2{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.gameuppers-card p{
  font-family: "Manrope", sans-serif;
font-size: 11px;
font-weight: 500;
color: white;

}
.gamename h4{
  font-family: "Space Grotesk", sans-serif;
  font-size: 29px;
  font-weight: 700;
  color: white;
}
.gameuppers-row{
  display: flex;
  flex-direction: row;

  gap: 50px;
  /* justify-content: space-; */
}
.gameuppers-col-card{
  width: 90%;
  height: 225px;
  background-color: rgba(26, 26, 26, 1);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  

}
.gamename2{
  height: 98px;
  width: 98px;
  background-color: rgba(125, 25, 29, 1);
  border: 1px solid white;
  color: white;
  border-radius: 50%;
  position: absolute;
  margin-left: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gamename2 h2{
  font-family: "Space Grotesk", sans-serif;
  font-size: 29px;
  font-weight: 700;
  color: white;
}



.gameuppers-col-card h3{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.gameuppers-col-card p{
  font-family: "Manrope", sans-serif;
font-size: 11px;
font-weight: 500;
color: white;

}

.gamename2-text{
  margin-left: 50px;
}

.flexss{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.flec-senter{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.talk{
  background-color: black;
}
.talk h1{
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
  color: white;
  text-align: center;
}
.talk h1 span{
  color: rgba(253, 165, 15, 1);
}
.Champions{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.talk{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.talk button{
  border: 1px solid rgba(98, 0, 122, 0.5);
  background-color: rgba(98, 0, 122, 1);
  padding: 20px 30px 20px 30px;
  font-family: "Manrope", sans-serif;
font-size: 14px;
font-weight: 700;
color: white;
border-radius: 100px;


}
.Responsiblegaming{
  background-image: url(./Assets/image\ 13.svg);
  padding-top: 200px;
  padding-bottom: 200px;
  background-position: center;
  background-size: cover;
}

.Responsiblegaming-card{
  Width:
  370px;
  Height:
  284px;
  background-color: rgba(26, 26, 26, 1);

  border-radius: 16px;
  padding: 30px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
text-align: center;
}
.Responsiblegaming-icon{
  Width:
98px;
Height:
98px;
background-color: rgba(98, 0, 122, 1);
border: 5px solid rgba(98, 0, 122, 0.4);
border-radius: 50%;
position: absolute;
margin-top: -80px;
display: flex;
justify-content: center;
align-items: center;

}
.Responsiblegaming-card h2{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.Responsiblegaming-card p{
  font-family: "Manrope", sans-serif;
font-size: 16px;
font-weight: 500;
color: rgba(226, 226, 226, 1);

}
.Responsiblegaming{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.FAQ{
background-color: black;
padding-top: 100px;
padding-bottom: 100px;
display: flex;
flex-direction: column;
align-items: center;
width: 100%;

}
.FAQ h1{
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
  color: white;
  text-align: center;
}
.FAQ h1 span{
 color: rgba(253, 165, 15, 1);
}
.questions{
  width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.payimg{
  width: 90%;
}

.questions-card p{
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.questions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questions-card {
  height: 92px;
  width: 80%;
  background-color: rgba(98, 0, 122, 1);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
 
  border-radius: 10px;
  cursor: pointer;
  padding-left: 50px;
  padding-right: 50px;
}


.questions-content {
  width: 80%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: rgba(98, 0, 122, 0.5);
  padding: 0 50px;
  border-radius: 0 0 10px 10px;
}

.questions-content p{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.questions-content ul li{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 15px;
  font-weight: 500;margin-bottom: 15px;
}

.active {
  max-height: 500px; /* Adjust based on content */
  padding: 15px 50px;
}

.FAQ button{
  padding: 20px 30px 20px 30px;
  background-color: rgba(98, 0, 122, 1);
  border: 4px solid rgba(98, 0, 122, 0.5);
  border-radius: 100px;
  color: white;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;


}
.asseen{
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  gap: 50px;
 
}
.FAQ h4{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400;
}
.truted{
  background-image: url(./Assets/group000.svg);

  padding-left: 100px;
  padding-right: 100px;

}
.truted-left{
  width: 50%;
  padding-top: 300px;
  padding-bottom: 150px;
}
.truted-right{
  width: 50%;
display: flex;
justify-content: center;
align-items: center;
}
.truted-right img{
  width: 80%;
}

.truted-left h1{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 700;

}
.truted-left h1 span{
  color: rgba(253, 165, 15, 1);
}
.truted-left p{
  color: white;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.truted{
  display: flex;
  flex-direction: row;

}
.stores{
  display: flex;
  gap: 20px;
}
.footer{
  background-color: black;
  border-top: 1px solid rgba(48, 48, 48, 1);

}
.footer-payment{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 20px 100px;
}
.footer-add{
  padding: 0px 100px;
  color: rgba(118, 118, 118, 1);

}
.footer-add h2{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;

}
.footer-add p{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(118, 118, 118, 1);
}
.footer-payment p{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(118, 118, 118, 1);
  margin-bottom: 15px;
}
.footer-payment-left{
  width: 50%;
}
.footer-payment-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;

}
.footer-top{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 100px;
}
.footer-top ul{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 600;

}
.footer-top ul li{

  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: rgba(118, 118, 118, 1);
  list-style: none;
  margin-bottom: 15px;
}
.footer-down{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 100px;
  padding-bottom: 30px;
}
.footer-down ul li{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media(max-width:990px){
  nav ul{
  gap: 20px;

  }
}
.bi-list{
  color: white;
  font-size: 16px !important;
  display: none;
}
@media(max-width:855px){
  nav ul{
  display: none;
  }
  .banner h1{
  font-size: 50px;

  }
.banner p{
  font-size: 18px;

}
.playercount p{
  font-family: "Manrope", sans-serif;
  font-size: 13px;
  font-weight:700;
  color: white;
}
.bi-list{
  display: block;
}
}

.bi-list {
  cursor: pointer;
}

/* Styles for the toggleable div */
.toggleDiv {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #000000d4;
  border: 1px solid #dddddd;
  border-radius: 50px;
  color: white;
  text-align: center;
margin-top: 150px;
padding-top: 50px;

}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  color: white;

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: #000000c1;
  position: fixed;
z-index: 100;
  top: 80px;
}
.donloadtheapp{
  background-color: black;
  padding-left: 200px;
  padding-right: 200px;
  color: white;
}
.donloadtheapp-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}




.rummycards-container {
  overflow-x: auto;
  white-space: nowrap;
}
.rummycards-container::-webkit-scrollbar {
  height: 0px; /* Height of the horizontal scrollbar */
  width: 0px;
}
.rummycards {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

.rummycard {
  display: inline-block;
  margin-right: 10px; /* Add spacing between cards */
  white-space: normal; /* Allow normal text wrapping inside cards */
}

@media(max-width:768px){
.banner-playingcards{
  width: 80%;
}
.bestone h2{
  font-size: 30px;

}
}
@media(max-width:500px){
  .banner h1{
    font-size: 35px;
  
    }

}


@media(max-width:1150px){

.playrummy{

  padding-left: 200px;
  padding-right: 200px;

}
}
@media(max-width:1000px){

  .playrummy{
    padding-top: 100px;
  padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
  
  }
  }
@media(max-width: 768px){
  .playrummy{
    padding-top: 100px;
  padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
  
  }
.playrummy h3{
  font-size: 35px;

}
}
.tab-divs , .flexxss-div {
  overflow-x: auto;
  white-space: nowrap;
}

@media(max-width: 1140px){

.gameuppers{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.gameuppers-left , .gameuppers-right{
  width: 100%;
}
.gameuppers-row{
  justify-content: center;
}
.gameuppers-col{
  width: 100%;
  display: flex;
  justify-content: center;
}
.gameuppers-col-card{
  width: 80%;
}
.gameuppers-col{
  justify-content: center;

}
.gamename2{

}
}
@media(max-width: 768px){
  .gameuppers-card{
    Width: 50%;
    Height: auto;
    padding-bottom: 25px;
  }
  .gameuppers-row{
    padding-left: 30px;
    padding-right: 30px;
  }
.gameuppers-col-card{
height: auto;
padding-bottom: 25px;
}
.gameuppers-col{
  padding-left: 30px;
  padding-right: 30px;
}
}
@media(max-width: 650px){
  .gameuppers-row{
    flex-direction: column;
    gap: 100px;
  }
  .gameuppers-card{
    Width: 100%;
  }
  .talk h1{
  font-size: 25px;

  }
}

.Champions {
  overflow-x: auto;
  white-space: nowrap;
}

.Champions img {
  display: inline-block;
  margin-right: 10px; /* Optional: Add some space between images */
}
@media(max-width: 1300px){
  .Responsiblegaming{
    padding-left: 30px;
    padding-right: 30px;
  }
.Responsiblegaming-card{
  Width:
  30%;
  Height:
  284px;
}

}
@media(max-width: 1300px){
  .Responsiblegaming-card{
   min-height: 284px;
   height: auto;
   padding-left: 15px;
   padding-right: 15px;
  }

}
@media(max-width: 870px){
  .Responsiblegaming{
    display: flex;
    flex-direction: column !important;
    gap: 100px !important;
  }
  .Responsiblegaming-card{
    width: 90%;
  }
  .truted-left h1{
    color: white;
    font-family: "Space Grotesk", sans-serif;
    font-size: 30px;
    font-weight: 700;
  
  }
  .truted{
    padding-left:50px;
    padding-right: 50px;
  
  }
}
@media(max-width: 870px){
  .Responsiblegaming-card{
    width: 90%;
  }
}
@media(max-width: 768px){
  .FAQ h1{
    color: white;
    font-family: "Space Grotesk", sans-serif;
    font-size: 25px !important;
    font-weight: 400;
  }
.questions-card {
  padding-left: 20px;
  padding-right: 20px;
  height: 92px;

  gap: 50px;

}
.questions-card img{
  height: 30px;
}
}

@media(max-width: 768px){
.truted{
  display: flex;
  flex-direction: column;
}
.truted-left , .truted-right{
  width: 100%;
}
.truted-left  h1{
  color: white;
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px !important;
  font-weight: 700;
}
}

@media(max-width: 500px){
  .truted-left  h1{
    color: white;
    font-family: "Space Grotesk", sans-serif;
    font-size: 25px !important;
    font-weight: 700;
  }
  .stores{
    flex-direction: column;
    align-items: start;
  }
  .truted-left{
  
    padding-top: 100px;
    padding-bottom: 100px;
  }
.truted-right{
  padding-bottom: 100px;
}
}
@media(max-width: 994px){
  .footer-top{
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  }

}

@media(max-width: 600px){
  .footer-down{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }

}
@media(max-width: 600px){

.footer-down img{
  height: 25px;

}
}
.asseen {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
}
.asseen-div , .Champions-div , .flexss-div , .tab-div{
  width: 100%;

  display: flex;
  /* justify-content: center; */
  flex-direction: row;
 align-items: center;


}
.asseen-divs , .Champions-divs , .flexss-divs , .tab-divso{
  width: 100%;
 display: flex;
 justify-content: space-evenly;
 flex-direction: row;
 gap: 50px;
 align-items: center;
 

}
.asseen img {
  flex: 0 0 auto;
 
  height: auto;
  scroll-snap-align: start;
  margin-right: 10px; /* space between images */
}
.Champions {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
}

.Champions img {
  flex: 0 0 auto;
 
  height: auto;
  scroll-snap-align: start;
  margin-right: 10px; /* Space between images */
}

.donloadtheapp-div h3{

  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.donloadtheapp-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 30px 50px; 
  border-radius: 15px;
  box-shadow: 0 0 5px 1px #48abe0,
              0 0 15px 2px #ebecca,
              0 0 10px 3px #8a2be2,
              0 0 10px 2px #ff1493;

}
.donloadtheapp-div button{
  padding: 15px 30px ;
  background-color: rgba(98, 0, 122, 1);
  border: 4px solid rgba(98, 0, 122, 0.5);
  border-radius: 100px;
  color: white;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.termsdiv{
  padding-left: 50px;
  padding-right: 50px;
  background-image: url(./Assets/image\ 41.svg);
}
.conditions{
  background-color: black;
  padding-left: 50px;
  padding-right: 50px;
  color: white;
}
.conditions ul li{

  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
color: #d0cfcf;
  
  margin-top: 15px;

}
.conditions ul li i{
  color: rgba(253, 165, 15, 1);
  font-size: 20px;
}
.conditions h1{
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.conditions p{
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 500;
color: #d0cfcf;
}
.conditions h4{
  font-family: "Space Grotesk", sans-serif;

  font-size: 25px;
  font-weight: 700;
color: #ffffff;
text-align: center;
}
.contactus{


  display: flex;
  flex-direction: row;
}
.contactus-left{
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
}
.contactus-right{
  width: 50%;
}
.contactusdiv{
  background-image: url(./Assets/group000.svg);
background-size: cover;
background-position: center;
  display: flex;
  flex-direction: column;
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  padding-top:200px;
  padding-bottom: 100px;
}
.contactusdiv h1{ 
  font-family: "Space Grotesk", sans-serif;

  font-size: 40px;
  font-weight: 700;
color: #ffffff;
text-align: center;
}
.contactusdiv h1 span{
  color: rgba(253, 165, 15, 1);
}
.contactusdiv p{
  text-align: center;
  margin-top: 10px;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
color: #d0cfcf;
}

.contactus-left h2{
  font-family: "Space Grotesk", sans-serif;

  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.contactus-left h3{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 500;
color: #d0cfcf;
}
.contactus-left h3 span{
  color: rgba(253, 165, 15, 1);

}

.contactus-right{
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;

}
.contactus-right input{
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  background: none;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding-left: 30px;

}
.contactus-right button{
  width: 100%;
  height: 50px;

  background-color: rgba(98, 0, 122, 1);
  border: 4px solid rgba(98, 0, 122, 0.5);
  border-radius: 10px;
  color: white;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.refferal{
  background-image: url(./Assets/group000.svg);
padding-top: 200px;
padding-bottom: 100px;

}
.refferal h1{
  font-family: "Manrope", sans-serif;
  font-size: 40px;
  font-weight: 500;
color: #ffffff;
text-align: center;
}
.refferal h1 span{
  color: rgba(253, 165, 15, 1);

}
.refferal h3{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
color: #d0cfcf;
text-align: center;
}
.reff-table{
padding-left: 200px;
padding-right: 200px;
}
.reff-table-div{
display: flex;
flex-direction: row;
text-align: center;
color: white;
font-family: "Manrope", sans-serif;
font-size: 15px;
font-weight: 500;
}

.reff-table-div-left{
  width: 50%;
  border: 1px solid white;
  padding-top: 20px;
  padding-bottom: 10px;
}
.reff-table-div-right{
  width: 50%;
  border: 1px solid white;
  padding-top: 20px;


}
.reff-table-div p{
  width: 100%;
  border-bottom: 1px solid white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.Howitworks{
  background-color: black;
  color: white;
  padding-left: 50px;
  padding-right: 50px;

}
.Howitworks h1{
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
color: #ffffff;
}
.Howitworks ul li{

  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}
.Howitworks p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.rules{
  color: white;
  background-color: black;
  padding-left: 50px;
  padding-right: 50px;
}
.rules h1{
  font-family: "Manrope", sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 15px;
}
.rules ul li{
  
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}
.rules p{
  font-family: "Space Grotesk", sans-serif;

  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}
.rules h4{
  font-family: "Space Grotesk", sans-serif;

  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.none{
  color: white;
  text-decoration: none;
}
.legal{
  background-image: url(./Assets/Group\ 39936.png);
padding-top: 150px;
padding-bottom: 100px;
padding-left: 150px;
padding-right: 150px;
background-size: cover;
}

.legal h1{
  font-family: "Space Grotesk", sans-serif;

  font-size: 40px;
  font-weight: 400;

  color: rgba(253, 165, 15, 1);

  margin-bottom: 15px;
  text-align: center;
}
.legal h1 span{
  color: white;

}
.legal p{
  font-family: "Manrope", sans-serif;


  font-size: 15px;
  font-weight: 400;
  color: white;
  text-align: center;


}
.legal h2{
  font-family: "Manrope", sans-serif;


  font-size: 25px;
  font-weight: 600;
  color: white;
}
.legal h3{
  font-family: "Manrope", sans-serif;


  font-size: 20px;
  font-weight: 400;
  color: white;
}
.legal h4{
  font-family: "Manrope", sans-serif;


  font-size: 15px;
  font-weight: 400;
  color: white;

}
.legal h2 span , .legal h3 span , .legal h4 span{
  color: rgba(253, 165, 15, 1);

}
.legal ul li{
  font-family: "Manrope", sans-serif;


  font-size: 15px;
  font-weight: 400;
  color: white;
  margin-bottom: 15px;
}
.download-app{

}
.dgame{
  display: flex;
  flex-direction: row;
  background-color: #0E1417;
  padding: 50px;
}
.dgame-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.dgame-left img{
  width: 50%;

}
.dgame-right{
  width: 50%;
  color: white;
}
.dgame-right h2{
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
}
.dgame-right p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #a8a9aa;
}
.tour{
  padding: 50px;
  background-color: black;
 padding-top: 120px;
 text-align: center;
}
.tour h2{
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  font-weight: 800;
  color: white;
}  
.Worldrecord{
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 50px;
  padding: 50px 50px;
}
.Worldrecord h3{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 800;
}
.Worldrecord p{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.flagship{
  text-align: center;
  padding: 50px;
  background-color: black;
  color: white;
}
.flagship-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 50px;
}
.flagship h2{
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.flagship p{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.flagship-card p{
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 800;
}
.flagship-card{
  width: 30%;

}
.flagship-card img{
  width: 100%;
  margin-bottom: 15px;
}
.Exciting-range{
padding: 50px;
background-color: black;
color: white;
}
.Exciting-range h2{
  font-family: "Space Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
}
.Exciting-range p{
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;

}
.Exciting-range-card{
  width: 25%;
  background-color: white;
  padding: 15px;
  border-radius: 15px;

}
.Exciting-range-card img{
  width: 100%;
}
.Exciting-range-card{
  text-align: left !important;
  color: black;

}
.Exciting-range-card h2{
  text-align: left;
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
}
.Exciting-range-card p{
  text-align: left;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 800;
  color: rgb(77, 227, 77);

}
.Exciting-range-card h3{
  text-align: left;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}
.Exciting-range-card button{
  width: 100%;
  height: 40px;
  border-radius: 10px;

  background: linear-gradient(180deg, #fdc830, #f37335) ;
  border: none;
  margin-top: 10px;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: white;

}
.Exciting-range-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.Rummytournaments-text{
  padding: 50px 100px;

  background-color: #171b20;
}
.Rummytournaments-text p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #a0a0a0;
}
.Rummytournaments-text h3{
  color: white;
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
}
.Rummytournaments-text h2{
  color: white;
  font-family: "Space Grotesk", sans-serif;

  font-size: 40px;
  font-weight: 500;
  text-align: center;
}
.RealCashRummy{
  padding: 50px 350px;
  background-color: black;
  padding-top: 100px;
}
.RealCashRummy h2{
  color: white;
  font-family: "Space Grotesk", sans-serif;

  font-size: 40px;
  font-weight: 500;
}
.RealCashRummy p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #a0a0a0;
}
.RealCashRummy img{
  width: 100%;
}
.RealCashRummy h3{
  color: white;
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
}
.refral{
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 150px;
  color: white;
  text-align: center;
}
.refral p{
  color: white;
  font-family: "Space Grotesk", sans-serif;

  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.DiscountCredits{
  background-color: #171B20;
  padding: 50px 150px;
  color: white; 



}
.DiscountCredits h2{
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}
.DiscountCredits p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #a0a0a0;
}
.play{
  padding-top: 80px;
  width: 100%;
}
.ResponsibleGaming{
  background-color: #0E1417;
  padding: 50px 150px;
}
.ResponsibleGaming h2{
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
  color: white;
}
.ResponsibleGaming p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #a0a0a0;
}
.ResponsibleGaming h3{
  font-family: "Space Grotesk", sans-serif;

  font-size: 30px;
  font-weight: 500;
  color: white;
  text-align: center;
}
.ResponsibleGaming-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.ResponsibleGaming-icon p{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 15px;
  text-align: center;
}
.ResponsibleGaming-icons{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 100px;
  margin-top: 50px;
}

.marquee {
  width: 100%; /* Full width of the container */
  overflow: hidden; /* Hide overflow content */
  white-space: nowrap; /* Prevent text from wrapping */
  box-sizing: border-box;
}

/* Marquee Text */
.marquee p {
  display: inline-block;
  padding-left: 100%; /* Start outside the container */
  animation: marquee 15s linear infinite; /* Animation effect */
  font-size: 20px;
  font-weight: 700;
  color: yellowgreen;
}

/* Keyframes for Animation */
@keyframes marquee {
  0% { transform: translateX(0); } /* Start position */
  100% { transform: translateX(-100%); } /* End position */
}
@media(max-width: 1350px){
  .dgame-left img{
    width: 90%;
  
  }
}
@media(max-width: 1100px){
  .dgame{
    flex-direction: column;
    gap: 30px;
  }
  .dgame-left ,  .dgame-right{
    width: 100%;
  }
  .dgame-left img{
    width: 50%;
  
  }
}
@media(max-width: 600px){
  .dgame{
    padding: 30px;
  }
}
@media(max-width: 600px){
  .Worldrecord{
flex-direction: column;
  }
  .flagship-card{

  }
  .Worldrecord{
    padding: 50px 0px;
  }

}
@media(max-width: 768px){
  .flagship-cards{
    flex-direction: column;
  }
  .flagship-card{
    width: 100%;
  }
  .Exciting-range{
    padding: 30px;
  }
  .Exciting-range-cards{
    display: flex;
    flex-direction: column;
  }
  .Exciting-range-card{
    width: 100%;
  }
  .Rummytournaments-text{
    padding: 30px;
  }
  .tour{
    padding: 30px;
 padding-top: 120px;

  }
  .tour h2{
    font-size: 35px;
  }
}
@media(max-width: 1200px){
  .footer-payment{
    display: flex;
    flex-direction: column;
    padding: 50px;
  }
  .footer-payment-left{
    width: 100%;

  }
  .footer-payment-right{
    justify-content: start;
    align-items: start;
    width: 100%;
  }
  .footer-add{
    padding: 10px 50px;
  }
}
@media(max-width: 768px){
  .footer-payment{
    padding: 30px;
  }
  .footer-payment img{
    width: 100%;
  }
  .footer-add{
    padding: 10px 30px;

  }
}
@media(max-width: 1100px){
  .RealCashRummy{
    padding: 50px 250px;
  padding-top: 100px;

  }
}
@media(max-width: 860px){
  .RealCashRummy{
    padding: 50px 150px;
  padding-top: 100px;

  }
  .reff-table{
    padding-left: 30px;
    padding-right: 30px;
    }
    .donloadtheapp{
      background-color: black;
      padding-left: 30px;
      padding-right: 30px;
    }
    .legal{
      padding-left: 50px;
padding-right: 50px;
    }
}
@media(max-width: 620px){
  .RealCashRummy{
    padding: 50px 30px;
  padding-top: 100px;

  }
  .ResponsibleGaming{
    background-color: #0E1417;
    padding: 50px 30px;
  }
  .donloadtheapp-div , .donloadtheapp-logo{
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 30px 20px; 

  }
  .legal{
    padding-left: 30px;
padding-right: 30px;
  }
  .termsdiv{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media(max-width: 1000px){
.contactusdiv{
  padding-left: 30px;
  padding-right: 30px;
}
.ResponsibleGaming{
  padding: 50px 30px;

}
}
@media(max-width: 768px){
.contactus{
  display: flex;
  flex-direction: column;
}
.contactus-left , .contactus-right{
  width: 100%;
}
.contactus-right{
  padding-left: 0px;
  padding-right: 0px;
}
.contactus-left{
 
  padding-left: 0px;
  padding-right: 0px;
}
}
@media(max-width: 630px){
.ResponsibleGaming-icons{
  flex-direction: column;
  gap: 40px;

}
.reff-table{
  padding-left: 0;
  padding-right: 0;
}
.reff-table-div p{
  height: 130px;

}
}
/* Container for the cards */
.rummycards-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Continuous scrolling animation for the cards */
.rummycards {
  display: flex;
  animation: scroll 30s linear infinite; /* Controls the continuous scrolling */
  gap: 50px;
}

/* Individual card styling */
.rummycard {
border-radius: 15px;
  transition: transform 0.5s ease; /* Smooth scaling transition */
}

/* Scrolling animation keyframes */
@keyframes scroll {
  0% {
      transform: translateX(100%); /* Start from the right */
  }
  100% {
      transform: translateX(-100%); /* End at the left */
  }
}
